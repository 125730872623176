import React, { useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Preview
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageType } from "../components/context/page-type";

// Components
import { MapComponent } from "../components/map/map";

// Context
import { useColorContext } from "../components/context/color-context";

const Page = styled.div`
  overflow-x: hidden;
`;

const ContentContainer = styled.div`
  & .full-width-text {
    & .text-container {
      grid-column: 1 / 7;

      & p {
        font-size: 40px;
        line-height: 52px;

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        @media (max-width: 768px) {
          font-size: 26px;
          line-height: 34px;
        }
      }
    }
  }

  & .text {
    & .text-container {
      grid-column: 2 / 6;

      @media (max-width: 768px) {
        grid-column: 1 / 7;
      }

      & .inner-text-container {
        max-width: 895px;
        margin: 0 auto;

        & .section-title {
          & h1 {
            font-size: 40px;
            line-height: 52px;

            @media (max-width: 768px) {
              font-size: 26px;
              line-height: 34px;
            }
          }
        }

        & .section-text {
          & ol {
            margin: 1em 0;

            & li {
              list-style: decimal;
              margin: 0 0 1em 20px;
              padding: 0 0 0 10px;

              &:last-of-type {
                margin: 0 0 0 20px;
              }
            }
          }
        }
      }
    }
  }

  & .gallery {
    grid-row-gap: 80px;

    @media (max-width: 768px) {
      grid-row-gap: 40px;
    }

    & .single-image-container {
      grid-column: span 2;

      @media (max-width: 1200px) {
        grid-column: span 3;
      }

      @media (max-width: 768px) {
        grid-column: 1 / 7;
      }

      & .inner-grid {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        height: 100%;
      }

      & .name-subtitle-container {
        min-height: 60px;
        // min-height: 90px;

        @media (max-width: 768px) {
          min-height: unset;
        }
      }

      & .subtitle {
        & p {
          margin: 0;
        }
      }

      & .text-image-container {
        flex: 1;

        & .image-container {
          position: relative;
          margin: 1em 0;
        }

        & .text {
          & p {
            &:first-of-type {
              margin-top: 0;
            }

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }

        & img {
          margin: 0 auto;

          object-fit: contain;

          top: 50%;
          transform: translateY(-50%);
          position: relative;

          @media (max-width: 768px) {
            top: unset;
            transform: unset;
          }

          &.landscape {
            width: 100%;
            height: 100%;
            max-height: calc(100% - 30px - 30px);

            @media (max-width: 768px) {
              max-height: 100%;
            }
          }

          &.portrait {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }

  & .support {
    & .section-title,
    & .support-logos {
      grid-column: 1 / 7;
    }

    & .support-logos {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      & .single-image-container {
        margin: 0 40px 0 0;

        &:last-of-type {
          margin: 0;
        }

        & img {
          width: auto;
          height: 30px;
        }

        @media (max-width: 768px) {
          margin: 0 15px 0 0;

          & img {
            height: 20px;
          }
        }
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 40px;

  margin: 70px 0;

  @media (max-width: 768px) {
    margin: 40px 0;
  }
`;

const Map = ({ data }) => {
  const [pageType, setPageType] = useContext(PageType);
  const { randomColors } = useColorContext();

  useEffect(() => {
    setPageType(`map`);
  }, []);

  return (
    <>
      <Helmet
        title={`${data.prismicMap.data.title.text} | Three Rivers | Bexley`}
        meta={[
          {
            name: "title",
            content: `${data.prismicMap.data.title.text} | Three Rivers | Bexley`,
          },
          {
            property: "og:title",
            content: `${data.prismicMap.data.title.text} | Three Rivers | Bexley`,
          },
          {
            property: "twitter:title",
            content: `${data.prismicMap.data.title.text} | Three Rivers | Bexley`,
          },
        ]}
      />
      <Page>
        <ContentContainer>
          <MapComponent
            locations={data.allPrismicProject.nodes}
            highlightColor={randomColors[1]}
          />
        </ContentContainer>
      </Page>
    </>
  );
};

export default withPreview(Map);

export const query = graphql`
  query {
    prismicMap {
      _previewable
      data {
        title {
          text
        }
      }
    }
    allPrismicProject(filter: { data: { show_on_map: { eq: true } } }) {
      nodes {
        id
        url
        data {
          title {
            html
          }
          subtitle {
            html
          }
          additional_text {
            html
          }
          text_description {
            html
          }
          event
          location {
            latitude
            longitude
          }
          image {
            alt
            fluid {
              srcSet
              src
            }
          }
        }
      }
    }
  }
`;

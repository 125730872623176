import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import GoogleMapReact from "google-map-react";
import { useWindowSize } from "react-use";

// Components
import { MapLocationPanel } from "./map-location-panel";

const MapContainer = styled.div`
  position: relative;
  height: ${props => props.windowHeight}px;
`;

const MapIcon = styled.button`
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;

  z-index: ${props => props.zIndex};

  &.active {
    width: 50px;
    height: 50px;

    & svg {
      width: 50px;
      height: 50px;
    }
  }
`;

export const MapComponent = ({ locations, highlightColor }) => {
  const windowHeight = use100vh();
  const [headerHeight, setHeaderHeight] = useState(0);
  const { width, height } = useWindowSize();

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const headerHeight = document.querySelector("header").offsetHeight;
      setHeaderHeight(headerHeight);
    }
  }, [width]);

  const defaultProps = {
    center: {
      lat: 51.4603085,
      lng: 0.1537691,
    },
    zoom: 13,
  };

  const customStyles = [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffff8f",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#dadada",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#f8c2ff",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
  ];

  const [activeMapLocation, setActiveMapLocation] = useState(null);

  const mapLocations = locations.map((location, index) => (
    <MapIcon
      onClick={() => setActiveMapLocation(location.id)}
      key={location.id}
      lat={location.data.location.latitude}
      lng={location.data.location.longitude}
      tabIndex="0"
      className={activeMapLocation === location.id ? `active` : ``}
      // icon={mapsvg}
      // aria-label={location.location.data.area}
    >
      <svg
        width="25"
        height="37"
        viewBox="0 0 25 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_101_4)">
          <path
            d="M23.74 12.4C23.74 23.74 16.18 23.74 12.4 35.08C8.62 23.74 1.06 23.74 1.06 12.4C1.06 6.14 6.14 1.06 12.4 1.06C18.66 1.06 23.74 6.14 23.74 12.4Z"
            fill="white"
          />
          <path
            d="M12.4 0C5.56 0 0 5.56 0 12.4C0 19.24 2.62 22.23 5.4 25.48C7.57 28.02 9.8 30.64 11.4 35.41C11.54 35.84 11.95 36.14 12.41 36.14C12.87 36.14 13.27 35.85 13.42 35.41C15.01 30.64 17.25 28.01 19.42 25.48C22.2 22.23 24.82 19.16 24.82 12.4C24.82 5.64 19.24 0 12.4 0ZM22.62 11.34H17.9C17.47 9.1 15.7 7.34 13.47 6.91V2.18C18.29 2.68 22.13 6.52 22.62 11.33V11.34ZM12.4 15.88C10.48 15.88 8.93 14.32 8.93 12.41C8.93 10.5 10.49 8.94 12.4 8.94C14.31 8.94 15.87 10.5 15.87 12.41C15.87 14.32 14.31 15.88 12.4 15.88ZM11.34 2.19V6.91C9.1 7.34 7.34 9.11 6.91 11.34H2.19C2.68 6.52 6.52 2.68 11.34 2.18V2.19ZM7.01 24.1C4.45 21.1 2.39 18.69 2.15 13.46H6.91C7.34 15.7 9.11 17.46 11.34 17.89V30.02C9.97 27.56 8.43 25.75 7.02 24.09L7.01 24.1ZM17.78 24.1C16.37 25.76 14.83 27.57 13.46 30.03V17.9C15.7 17.47 17.46 15.7 17.89 13.47H22.65C22.41 18.7 20.35 21.11 17.78 24.11V24.1Z"
            fill="#1D1D1B"
          />
        </g>
        <defs>
          <clipPath id="clip0_101_4">
            <rect width="24.8" height="36.14" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </MapIcon>
  ));

  return (
    <MapContainer windowHeight={windowHeight}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyD6Sn27VLheR1zvS7IbLqamkJ_-A46zdrc",
        }}
        // onChange={onChange}
        center={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
          styles: [...customStyles],
        }}
        yesIWantToUseGoogleMapApiInternals
        // onGoogleApiLoaded={() => this.drawMapLocations()}
      >
        {mapLocations}
      </GoogleMapReact>

      <MapLocationPanel
        highlightColor={highlightColor}
        activeMapLocation={activeMapLocation}
        setActiveMapLocation={setActiveMapLocation}
        location={
          activeMapLocation !== null
            ? locations.filter(location => location.id === activeMapLocation)[0]
            : null
        }
        isActive={activeMapLocation !== null}
        windowHeight={windowHeight}
      />
    </MapContainer>
  );
};

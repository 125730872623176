import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const MapLocationContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;

  background-color: ${props => props.highlightColor};
  z-index: 100;

  min-height: ${props => props.windowHeight}px;
  max-width: 600px;
  width: 100%;
  overflow-y: scroll;

  transition: 300ms transform ease;
  padding: 20px 50px 30px 30px;

  &.closed {
    transform: translateX(100%);
  }

  &.open {
    transform: translateX(0);
  }

  & button {
    display: block;
    z-index: 1000;
    margin: 0 0 1em 0;
  }

  & .image-container {
    margin: 1em 0;
  }

  & .link-container,
  & .additional-text {
    & p {
      font-size: 16px;
      line-height: 23px;

      & a {
        text-decoration: none;
      }
    }
  }

  & .link-container {
    & p {
      margin: 0;
    }
  }
`;

export const MapLocationPanel = ({
  location,
  setActiveMapLocation,
  isActive,
  windowHeight,
  highlightColor,
}) => {
  return (
    <MapLocationContainer
      className={isActive ? `open` : `closed`}
      windowHeight={windowHeight}
      highlightColor={highlightColor}
    >
      {location !== null && (
        <>
          <button type="button" onClick={() => setActiveMapLocation(null)}>
            ✕
          </button>

          <div className="content-container">
            <div
              className="title uppercase"
              dangerouslySetInnerHTML={{
                __html: location.data.title.html,
              }}
            />
            <div
              className="subtitle"
              dangerouslySetInnerHTML={{
                __html: location.data.subtitle.html,
              }}
            />

            <div className="image-container">
              {location.data.image.fluid !== null && (
                <img
                  srcSet={location.data.image.fluid.srcSet}
                  src={location.data.image.fluid.src}
                  alt={location.data.image.alt}
                  loading={`eager`}
                />
              )}
            </div>

            <div
              className="text-container"
              dangerouslySetInnerHTML={{
                __html: location.data.text_description.html,
              }}
            />

            <div
              className="additional-text"
              dangerouslySetInnerHTML={{
                __html: location.data.additional_text.html,
              }}
            />

            <div className="link-container">
              <p>
                <Link to={location.url}>
                  Go to {location.data.event ? `event` : `project`} page
                </Link>
              </p>
            </div>
          </div>
        </>
      )}
    </MapLocationContainer>
  );
};
